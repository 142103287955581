:root {
  --logo-color: rgb(26, 32, 44, 0.511);
  --button-color: linear-gradient(195deg, #49a3f1, #1a73e8);
  --text-color: white;
  --sidnav-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  --card-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  --card-Bg: #fff;
  --theme-Bg: #fff;
  --snapshot-des-text: #90809a;
  --snapshot-title: #334666;
  --snapshot-des-heading: #7b809a;
  --SnapshotButton: #7b809a;
  --PurchaseButton: #7b809a;
  --cards-color: linear-gradient(195deg, #49a3f1, #1a73e8);
  --cards-box-shadow: 0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%),
    0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%);
}
a.ghl-theme-list.active > li > .css-10ldvol,
a.ghl-theme-list.active > li > .css-1x3v3vy {
  opacity: 1;
  background: transparent;
  color: #344767;
  box-shadow: none;
  background: var(--logo-color) !important;
  color: var(--text-color) !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100% !important;
  padding: 0.3rem 0rem;
  margin: 0.09375rem 0rem;
  /* border-radius: 0.575rem !important; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  box-shadow: none !important;
}
a.ghl-theme-list > li > div:hover,
a.ghl-theme-list > li > div:focus {
  background: var(--logo-color) !important;
  padding: 0.3rem 0rem;
  margin: 0.09375rem 0rem;
}
a.ghl-theme-list > li > div {
  padding: 0.3rem 0rem;
  margin: 0.09375rem 0rem;
  border-radius: 0px !important;
  font-weight: 500 !important;
  color: var(--text-color) !important;
}

a.ghl-theme-list > li > div > div:nth-child(2) > span {
  font-family: var(--sidnav-font-family) !important;
}
.ghl-theme-sidnav-button{
    box-shadow:none !important;
    border-radius: 0px !important;
    width: 100% !important;
    /* padding: 2rem 0px !important; */
    background:var(--logo-color) !important;
    color:var(--text-color) !important;
   font-family: var(--sidnav-font-family) !important;
}
