:root{
    --NavbarBg:rgba(255, 255, 255, 0.8);
}
.custom-navbar{
    display: flex !important;
    justify-content: flex-start !important;
}
a.testing.active>li>div>li{
    background: linear-gradient(195deg, #49a3f1, #1A73E8) !important;
    color: #fff;
}
a.true>li>div>div{
    color:#1A73E8 ;
    text-decoration: underline;
}
.ghl-appbar{
    background: var(--NavbarBg) !important;
    border-radius: 0px !important;
    width: 100% !important;
    box-shadow: 0 2px 3px rgba(0,0,0,.11) !important;
}
.theme-appbar{
    background: var(--NavbarBg) !important;
}