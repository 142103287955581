:root{
    --logo-color: rgb(26, 32, 44, 0.511);
    --button-color:linear-gradient(195deg, #49a3f1, #1A73E8);
    --text-color:white;
    --sidnav-font-family:"Roboto","Helvetica","Arial",sans-serif;
    --card-font-family:"Roboto","Helvetica","Arial",sans-serif;
    --card-Bg:#fff;
    --theme-Bg:#fff;
    --snapshot-des-text:#90809A;
    --snapshot-title:#334666;
    --snapshot-des-heading:#7B809A;
    --SnapshotButton:#7B809A;
    --PurchaseButton:#7B809A;
    --cards-color:linear-gradient(195deg, #49a3f1, #1A73E8);
    --cards-box-shadow:0rem 0.25rem 1.25rem 0rem rgb(0 0 0 / 14%), 0rem 0.4375rem 0.625rem -0.3125rem rgb(0 187 212 / 40%);


    }
    .main_body{
        background: var(--theme-Bg) !important;
        min-height: 100vh !important;
    }
    .purchase-btn{
        color: var(--PurchaseButton) !important;
        border-color:  var(--PurchaseButton) !important;
    }
    .snapshot-btn{
        color: var(--SnapshotButton) !important;
        border-color:  var(--SnapshotButton) !important;
    }
    .snapshot-btn , .snapshot-btn>a{
        color: var(--SnapshotButton) !important;
    }
 a.defeult-theme-list1 > li > div:hover,  a.defeult-theme-list1 > li > div:focus {
    background:   var(--logo-color) !important;
}
 a.defeult-theme-list1 > li > div {
font-weight: 500 !important;
color:var(--text-color) !important;
}

a.defeult-theme-list1 > li > div>div:nth-child(2)>span {
   font-family: var(--sidnav-font-family) !important;
    }
.defeult-theme-side-nave-text{
color:var(--text-color) !important;

}
.defeult-theme-side-nave-text>span{
    font-family: var(--sidnav-font-family) !important;
    
    }
.defeult-theme-card{
    background: var(--cards-color) !important;
    box-shadow: var( --cards-box-shadow) !important;

}
.defeult-theme-card-Bg{
background: var(--card-Bg) !important;
}
.snapshot-des-text{
    color:var(--snapshot-des-text) !important;
    font-family: var(--card-font-family) !important;
}
.snapshot-title{
    color: var(--snapshot-title) !important;
    font-family: var(--card-font-family) !important;


}
.snapshot-des-heading{
    color: var(--snapshot-des-heading) !important;
    font-family: var(--card-font-family) !important;

}
a.defeult-theme-list1.active > li > .css-10ldvol,a.defeult-theme-list1.active > li > .css-1x3v3vy{
    opacity: 1;
    background: transparent;
    color: #344767;
    box-shadow: none;
    background:var(--logo-color) !important;
    color: var(--text-color) !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0.625rem;
    margin: 0.09375rem 1rem;
    border-radius: 0.575rem !important;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px !important;
}
.defeult-theme-button{
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px !important;
    background:var(--logo-color) !important;
    color:var(--text-color) !important;
    /* background: #3d507b !important; */

}
.sidnav-button{
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px !important;
    background:var(--logo-color) !important;
    color:var(--text-color) !important;
   font-family: var(--sidnav-font-family) !important;

}
a.defeult-theme-list > li > .css-mvs481:hover,  a.defeult-theme-list > li > .css-mvs481:focus {
    background:var(--logo-color) !important;
    padding: 0.84rem 0.625rem;
    margin: 0.09375rem 1rem;
    margin-bottom: 5px !important;

}
 a.defeult-theme-list > li > .css-mvs481 {
font-weight: 500 !important;
padding: 0.84rem 0.625rem;
    margin: 0.09375rem 1rem;
    margin-bottom: 5px !important;
    color: var(--text-color) !important;

}
a.defeult-theme-list > li > .css-mvs481>div:nth-child(1)>div>span {
        font-family: var(--sidnav-font-family) !important;
    
    }
 a.defeult-theme-list.active > li > .css-mvs481{
    opacity: 1;
    box-shadow: none;
    background:var(--logo-color) !important;
    color: var(--text-color) !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 0.84rem 0.625rem;
    margin: 0.09375rem 1rem;
    border-radius: 0.575rem !important;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px !important;
}